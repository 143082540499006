






















































































































































import {Component, Watch} from 'vue-property-decorator';
import SettingsItem from '@/components/SettingsItem.vue';
import ChatInfoUser from '@/components/ChatInfoUser.vue';
import VcSwitch from '@/components/VCSwitch.vue';
import DialogAddDefaultBackupContact from '@/components/DialogAddDefaultBackupContact.vue';
import {settingsStore} from '@/store/modules/settings';
import {mixins} from 'vue-class-component';
import Notifications from '@/components/mixins/Notifications';
import ToolTip from '@/components/custom/ToolTip.vue'

@Component({
  name: 'backup',
  components: {DialogAddDefaultBackupContact, VcSwitch, ChatInfoUser, SettingsItem, ToolTip}
})
export default class Backup extends mixins(Notifications) {

  showDialogAddDefaultBackupContact: boolean = false;
  autoSwitcher: boolean = false
  autoForward: boolean | null = null
  autoGroup: boolean | null = null

  @Watch('autoForward') onForwardChanged(after, before) {
    if (before === null) {
      return
    }
    this.autoSwitcher = !this.autoSwitcher
    this.backup!.autoForward = !this.backup!.autoForward
    this.backup!.autoGroup = !this.backup!.autoGroup
    if (this.autoForward === this.autoGroup) {
      this.autoGroup = !this.autoForward
    }
  }

  @Watch('autoGroup') onGroupChanged(after, before) {
    if (before === null) {
      return
    }
    this.autoSwitcher = !this.autoSwitcher
    if (this.autoForward === this.autoGroup) {
      this.autoForward = !this.autoGroup
    }
  }

  @Watch('backup', {deep: true, immediate: false}) onChanged(after, before) {
    if (!before) {
      return;
    }
    settingsStore.changeBackup(after);
  }

  get backup() {
    return settingsStore.businessSettings?.backup;
  }

  get backupDisable() {
    return !settingsStore.businessSettings?.away.active
  }

  get backupContact() {
    return this.backup?.contact;
  }

  onRemoveBackingContact() {
    if (this.backup) {
      this.backup.contact = null;
    }
  }

  onAddBackingContact() {
    this.showDialogAddDefaultBackupContact = true;
  }

  onDefaultContactSelected(contact: any) {
    settingsStore.setBackupContact(contact)
  }

  onSwitch() {
    if (this.backupDisable) {
      this.showWarning('Please, activate Away Messages option first (Settings -> Notifications -> Away Messages)',
          null, 5000)
    } else {
      if (!this.autoForward && !this.autoGroup) {
        this.autoForward = true
      }
    }
  }

  created() {
    if (this.backup) {
      this.autoSwitcher = this.backup.autoForward
      // this.autoForward = this.backup.autoForward
      // this.autoGroup = this.backup.autoGroup
      this.autoForward = false
      this.autoGroup = true
    }
  }
}
